<script setup lang="ts">
import type { MediaProjectType } from '~/api/gen'

const props = defineProps({
  data: null
})

const router = useRouter()

function continueProject() {
  router.push(`/media-project-draft/${props.data.id}`)
}
const { t } = useI18n()

const state = reactive({
  svg: { backgroundImage: '', backgroundPosition: '', backgroundRepeat: '' },
  inProgress: false,

})

// dynamic  import Logo from `~/svg/${props.categoryName}.svg?url`
import(`../../svg/${props.data.categoryName}.jpg`)
  // .then(e => state.svg = { backgroundImage: `url(${e.default})` })
  .then(e => state.svg = { backgroundImage: `url(${e.default})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat' })
</script>

<template>
  <div class="group custom-shadow max-w-10rem p-1rem hover:cursor-pointer" @click="continueProject()">
    <div class="h-8rem w-8rem flex border-1 border-gray rounded-50% bg-gray-200 group-hover:border-gray-5" :style="state.svg" />

    <div class="mt-4 overflow-hidden truncate text-ellipsis text-center text-primary font-bold">
      {{ props.data.name }}
    </div>
  </div>
</template>

<style scoped lang="postcss">
.custom-shadow:hover {
  box-shadow:
    0 0 10px rgb(0 0 0 / 0.1),
    0px 0px 10px rgb(0 0 0 / 0.1);
}

.dark .custom-shadow:hover {
  box-shadow:
    0 0 10px rgb(255 255 255 / 0.9),
    0px 0px 10px rgb(255 255 255 / 0.9);
}
</style>
