<script setup lang="ts">
import type { ListVideoCategoryDto } from '~/api/gen'

import { storeToRefs } from 'pinia'

import { MediaProjectType, VideoCategoryControllerApi } from '~/api/gen'
import { getConfiguration, httpClient } from '~/axios'

const { t } = useI18n()
const userStore = useUserStore()
const router = useRouter()

const { user } = storeToRefs(userStore)

const state = reactive({
  uploadingConsent: false,
  categories: [] as ListVideoCategoryDto[],
})

const api = new VideoCategoryControllerApi(getConfiguration(), undefined, httpClient)
onMounted(async () => {
  const categoriesResponse = await api.getAll()
  state.categories = categoriesResponse.data

  await userStore.updateAuthorData()
})

async function createNewDraft(category: ListVideoCategoryDto) {
  await router.push({ path: '/media-project-draft/create', query: { category: category.id, categoryName: category.name, type: MediaProjectType.JoyfulOccasion } })
}

const error = ref<Error | null>(null)
onErrorCaptured((e: Error) => {
  error.value = e
  return true
})
</script>

<template>
  <div :class="user.isLoaded() ? 'mt-16 flex justify-center mx-auto' : 'hidden'" class="">
    <div class="mb-10">
      <h1 class="heading mb-8">
        {{ t('intro.hi', { name: userStore.user.firstName }) }}
      </h1>

      <p class="mb-8">
        {{ t('video-categories.select-info') }}
      </p>
      <div class="grid grid-cols-1 mb-12 gap-x-8 gap-y-8 sm:grid-cols-3">
        <ImmaCreateEventButton v-for="category in state.categories" :key="category.id" :category-name="category.name" :action="() => createNewDraft(category)">
          {{ t(`video-categories.${category.name}`, category.name, { default: category.name, missingWarn: false }) }}
        </ImmaCreateEventButton>
      </div>

      <!-- <h2 class="heading">
        {{ t('global.storage') }}
      </h2>
      <div class="mb-8">
        <p v-if="user.storageSeats === 0" class="mb-2">
          {{ t('pricing.free-tier-active') }}
        </p>
        <p v-else class="mb-2">
          {{ t('pricing.storage-seats-used') }} {{ userStore.user.storageSeats }}
        </p>
        <p>
          {{ formatToGB(userStore.user.usedSpace ?? 0) }} / {{ formatToGB(userStore.user.totalSpace ?? 0) }} GB {{ t('global.used') }}
        </p>
      </div> -->
      <h2 class="heading">
        {{ t("global.recently-started") }}
      </h2>

      <div v-if="error" :error="error">
        {{ error.message }}
      </div>
      <Suspense>
        <RecentProjects />
        <template #fallback>
          Loading...
        </template>
      </Suspense>
    </div>
  </div>
</template>

<route lang="yaml">
meta:
  layout: home
</route>
