export const routes = [
  {
    path: '/',
    name: '/',
    component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/index.vue'),
    /* no children */
    meta: {
      "layout": "home"
    }
  },
  {
    path: '/:all(.*)',
    name: '/[...all]',
    component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/[...all].vue'),
    /* no children */
    meta: {
      "layout": 404
    }
  },
  {
    path: '/backoffice',
    /* internal name: '/backoffice' */
    /* no component */
    children: [
      {
        path: 'author',
        /* internal name: '/backoffice/author' */
        /* no component */
        children: [
          {
            path: ':id',
            name: '/backoffice/author/[id]',
            component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/backoffice/author/[id].vue'),
            /* no children */
            meta: {
              "transition": "slide-fade",
              "layout": "wide",
              "role": "backoffice"
            }
          }
        ],
      },
      {
        path: 'available-media-projects',
        name: '/backoffice/available-media-projects',
        component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/backoffice/available-media-projects.vue'),
        /* no children */
        meta: {
          "transition": "slide-fade",
          "layout": "wide",
          "role": "backoffice"
        }
      },
      {
        path: 'customer-management',
        name: '/backoffice/customer-management',
        component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/backoffice/customer-management.vue'),
        /* no children */
        meta: {
          "transition": "slide-fade",
          "layout": "wide",
          "// role": "WILL_MANAGER"
        }
      },
      {
        path: 'invitations',
        /* internal name: '/backoffice/invitations' */
        /* no component */
        children: [
          {
            path: 'manage',
            name: '/backoffice/invitations/manage',
            component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/backoffice/invitations/manage.vue'),
            /* no children */
            meta: {
              "transition": "slide-fade",
              "layout": "wide",
              "role": "backoffice"
            }
          }
        ],
      },
      {
        path: 'media-projects-created-by-customers',
        name: '/backoffice/media-projects-created-by-customers',
        component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/backoffice/media-projects-created-by-customers.vue'),
        /* no children */
        meta: {
          "transition": "slide-fade",
          "layout": "wide",
          "role": "backoffice"
        }
      },
      {
        path: 'reset-offline-card',
        name: '/backoffice/reset-offline-card',
        component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/backoffice/reset-offline-card.vue'),
        /* no children */
        meta: {
          "transition": "slide-fade",
          "layout": "wide",
          "role": "backoffice"
        }
      }
    ],
  },
  {
    path: '/draft-overview',
    name: '/draft-overview',
    component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/draft-overview.vue'),
    /* no children */
    meta: {
      "layout": "wide"
    }
  },
  {
    path: '/general-error',
    name: '/general-error',
    component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/general-error.vue'),
    /* no children */
    meta: {
      "layout": "default"
    }
  },
  {
    path: '/help',
    name: '/help',
    component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/help.vue'),
    /* no children */
    meta: {
      "layout": "default"
    }
  },
  {
    path: '/i',
    /* internal name: '/i' */
    /* no component */
    children: [
      {
        path: ':shortId',
        /* internal name: '/i/[shortId]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/i/[shortId]/',
            component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/i/[shortId]/index.vue'),
            /* no children */
            meta: {
              "transition": "slide-fade",
              "layout": "public",
              "isPublic": true
            }
          },
          {
            path: 'gallery',
            name: '/i/[shortId]/gallery',
            component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/i/[shortId]/gallery.vue'),
            /* no children */
            meta: {
              "transition": "slide-fade",
              "layout": "public",
              "isPublic": true
            }
          }
        ],
      }
    ],
  },
  {
    path: '/invitations',
    /* internal name: '/invitations' */
    /* no component */
    children: [
      {
        path: '',
        name: '/invitations/',
        component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/invitations/index.vue'),
        /* no children */
      },
      {
        path: ':shortId',
        /* internal name: '/invitations/[shortId]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/invitations/[shortId]/',
            component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/invitations/[shortId]/index.vue'),
            /* no children */
          },
          {
            path: 'gallery',
            name: '/invitations/[shortId]/gallery',
            component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/invitations/[shortId]/gallery.vue'),
            /* no children */
          },
          {
            path: 'video',
            name: '/invitations/[shortId]/video',
            component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/invitations/[shortId]/video.vue'),
            /* no children */
            meta: {
              "layout": "fullwidth"
            }
          }
        ],
      }
    ],
  },
  {
    path: '/media-library',
    /* internal name: '/media-library' */
    /* no component */
    children: [
      {
        path: '',
        name: '/media-library/',
        component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/media-library/index.vue'),
        /* no children */
      },
      {
        path: 'add',
        /* internal name: '/media-library/add' */
        /* no component */
        children: [
          {
            path: ':mediaProjectId',
            name: '/media-library/add/[mediaProjectId]',
            component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/media-library/add/[mediaProjectId].vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'play',
        /* internal name: '/media-library/play' */
        /* no component */
        children: [
          {
            path: ':id',
            name: '/media-library/play/[id]',
            component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/media-library/play/[id].vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/media-project',
    /* internal name: '/media-project' */
    /* no component */
    children: [
      {
        path: ':id',
        /* internal name: '/media-project/[id]' */
        /* no component */
        children: [
          {
            path: 'assets',
            name: '/media-project/[id]/assets',
            component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/media-project/[id]/assets.vue'),
            /* no children */
          },
          {
            path: 'connect-account',
            name: '/media-project/[id]/connect-account',
            component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/media-project/[id]/connect-account.vue'),
            /* no children */
          },
          {
            path: 'delete-sent-video',
            name: '/media-project/[id]/delete-sent-video',
            component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/media-project/[id]/delete-sent-video.vue'),
            /* no children */
          },
          {
            path: 'recipient',
            name: '/media-project/[id]/recipient',
            component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/media-project/[id]/recipient.vue'),
            /* no children */
          },
          {
            path: 'time',
            name: '/media-project/[id]/time',
            component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/media-project/[id]/time.vue'),
            /* no children */
          },
          {
            path: 'video',
            name: '/media-project/[id]/video',
            component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/media-project/[id]/video.vue'),
            /* no children */
          }
        ],
      }
    ],
  },
  {
    path: '/media-project-draft',
    /* internal name: '/media-project-draft' */
    /* no component */
    children: [
      {
        path: ':id',
        name: '/media-project-draft/[id]',
        component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/media-project-draft/[id].vue'),
        /* no children */
        meta: {
          "layout": "fullwidth"
        }
      },
      {
        path: 'business',
        /* internal name: '/media-project-draft/business' */
        /* no component */
        children: [
          {
            path: '',
            name: '/media-project-draft/business/',
            component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/media-project-draft/business/index.vue'),
            /* no children */
          },
          {
            path: ':id',
            name: '/media-project-draft/business/[id]',
            component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/media-project-draft/business/[id].vue'),
            /* no children */
            meta: {
              "layout": "fullwidth"
            }
          }
        ],
      }
    ],
  },
  {
    path: '/p',
    /* internal name: '/p' */
    /* no component */
    children: [
      {
        path: ':shortId',
        /* internal name: '/p/[shortId]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/p/[shortId]/',
            component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/p/[shortId]/index.vue'),
            /* no children */
            meta: {
              "transition": "slide-fade",
              "layout": "public",
              "isPublic": true
            }
          },
          {
            path: 'activation-success',
            name: '/p/[shortId]/activation-success',
            component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/p/[shortId]/activation-success.vue'),
            /* no children */
            meta: {
              "transition": "slide-fade",
              "layout": "public",
              "isPublic": true
            }
          }
        ],
      }
    ],
  },
  {
    path: '/payment',
    /* internal name: '/payment' */
    /* no component */
    children: [
      {
        path: ':mediaProjectId',
        /* internal name: '/payment/[mediaProjectId]' */
        /* no component */
        children: [
          {
            path: '',
            name: '/payment/[mediaProjectId]/',
            component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/payment/[mediaProjectId]/index.vue'),
            /* no children */
          },
          {
            path: 'cancel',
            name: '/payment/[mediaProjectId]/cancel',
            component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/payment/[mediaProjectId]/cancel.vue'),
            /* no children */
          },
          {
            path: 'success',
            name: '/payment/[mediaProjectId]/success',
            component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/payment/[mediaProjectId]/success.vue'),
            /* no children */
          }
        ],
      },
      {
        path: 'invitations',
        /* internal name: '/payment/invitations' */
        /* no component */
        children: [
          {
            path: ':shortId',
            /* internal name: '/payment/invitations/[shortId]' */
            /* no component */
            children: [
              {
                path: 'cancel',
                name: '/payment/invitations/[shortId]/cancel',
                component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/payment/invitations/[shortId]/cancel.vue'),
                /* no children */
              },
              {
                path: 'success',
                name: '/payment/invitations/[shortId]/success',
                component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/payment/invitations/[shortId]/success.vue'),
                /* no children */
              }
            ],
          }
        ],
      }
    ],
  },
  {
    path: '/project-overview',
    name: '/project-overview',
    component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/project-overview.vue'),
    /* no children */
  },
  {
    path: '/README',
    name: '/README',
    component: () => import('C:/Users/root/Dev/knatec/imma/repo/webapp/src/pages/README.md'),
    /* no children */
  }
]

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data.router = _router
  }
}

if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[unplugin-vue-router:HMR] Cannot replace the routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.routes) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

