import type { Language } from '~/modules/i18n'
import dayjs from 'dayjs'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { AuthorControllerApi } from '~/api/gen'
import { getConfiguration, httpClient } from '~/axios'
import { getCurrentLanguage, i18n, loadLanguageAsync } from '~/modules/i18n'
import { useKeycloak } from '~/modules/keycloak'
import { User } from '../services/user'

const emptyUser = new User('en', 'loading')

const init: User = emptyUser

const initialUser = new User('en', init.id, undefined, undefined, init.firstName, init.lastName)

export const useUserStore = defineStore('user', () => {
  /**
   * Current name of the user.
   */
  const user = ref(initialUser)
  const localeDateFormat = ref()

  const initLanguage = getCurrentLanguage()
  localStorage.setItem('language', initLanguage)
  user.value.language = initLanguage
  const { locale } = i18n.global
  locale.value = initLanguage
  dayjs.locale(initLanguage)
  localeDateFormat.value = dayjs().localeData().longDateFormat('L')

  async function setLoggedInUser(loggedInUser: User): Promise<void> {
    user.value = loggedInUser
    await changeLanguage(user.value.language)
  }

  function setPublicUser(loggedInUser: User): void {
    user.value = loggedInUser
  }

  const isLoggedIn = computed(() => {
    return user.value?.token
  })

  async function changeLanguage(language: Language) {
    localStorage.setItem('language', language)
    user.value.language = language
    dayjs.locale(language)
    localeDateFormat.value = dayjs().localeData().longDateFormat('L')
    await loadLanguageAsync(language)
    const authorApi = new AuthorControllerApi(getConfiguration(), undefined, httpClient)
    await authorApi.updateLanguage(user.value.id, {
      languageCode: language,
    })
  }

  async function logout() {
    user.value = emptyUser

    const keycloak = useKeycloak()
    if (keycloak.authenticated)
      await keycloak.logout()
  }

  async function login(redirectUri: string) {
    const keycloak = useKeycloak()
    await keycloak.login({ redirectUri })
  }

  async function updateToken(minValidityInSeconds: number): Promise<boolean> {
    const keycloak = useKeycloak()
    try {
      if (await keycloak.updateToken(minValidityInSeconds)) {
        user.value.token = keycloak.token
        user.value.refreshToken = keycloak.refreshToken
        return true
      }
      return false
    }
    catch (e) {
      return false
    }
  }

  async function updateAuthorData(): Promise<void> {
    const authorApi = new AuthorControllerApi(getConfiguration(), undefined, httpClient)
    const author = await authorApi.get1(user.value.id)
    const { usedSpace, totalSpace, needsWillRegistration, authorStatus } = author.data.value!
    const { isAfterDeathPackageActive, storageSeats, totalMediaProjectsAfterDeath } = author.data.value!
    const { totalMediaProjectCount, dateOfBirth, cookiesAcceptedAt } = author.data.value!
    Object.assign(user.value, {
      usedSpace,
      totalSpace,
      needsWillRegistration,
      authorStatus,
      isAfterDeathPackageActive,
      storageSeats,
      totalMediaProjectsAfterDeath,
      totalMediaProjectCount,
      dateOfBirth: dateOfBirth ? new Date(dateOfBirth) : null,
      cookiesAcceptedAt: cookiesAcceptedAt ? new Date(cookiesAcceptedAt) : null,
    })
  }

  return {
    user,
    localeDateFormat,
    setLoggedInUser,
    setPublicUser,
    isLoggedIn,
    logout,
    login,
    updateToken,
    changeLanguage,
    updateAuthorData,
  }
})

if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useUserStore, import.meta.hot))
